import React from "react";
import Navheader from "./components/Navheader";
import Footerbottom from "./components/Footerbottom";

function Whowearenow() {
  return (
    <div className="w-[100%] h-screen">
      <Navheader />

      <div className="w-[100%] md:flex flex-1  gap-4 h-screen  justify-between  content-center items-center px-4 py-4 ">
        <div className="h-[300px] md:w-[50%] rounded-md shadow-md px-4 py-4">
          <h2 className=" text-[20px] text-[#401f68] font-[900]">Our Mission:</h2>
          <p className="text-justify">
            AROLHD aims to raise awareness of human rights issues and provide
            legal aid services to vulnerable individuals and marginalized
            populations around the world. We hold the firm belief that every
            person deserves to be treated fairly and equally under the law, and
            that human dignity as the bedrock of humanity must be nurtured, for
            social justice and good society.
          </p>
        </div>
        <div className="h-[300px] md:w-[50%] rounded-md shadow-md px-4 py-4">
          <h2 className=" text-[20px] text-[#401f68] font-[900]">Our vision </h2>
          <p className="text-justify">
            Our vision is the attainment of a just world where human rights are
            respected, the rule of law is upheld, and everyone is treated with
            dignity and respect.
          </p>
        </div>
      </div>

      <Footerbottom/>
    </div>
  );
}


export default Whowearenow;
