import React from "react";
import Navheader from "./components/Navheader";

function Donate() {
  return (
    <div className="w-[100%] h-screen">
      <Navheader />

      <div className="px-10 py-10">
        <p className="pb-[50px] font-[900] text-[30px]">
          {" "}
          Our vision as a social justice group is to continuously seek to
          strengthen the Nigerian society, through the advancement of social
          justice causes.
        </p>
        <p className="pb-[10px]">
          By this , we hope to promote and enrich the rule of law and ensure
          that all persons within the Nigerian society is accountable to our
          societal laws , and that there are clear and fair processes for
          enforcing our laws. Support our NGO’s effort towards social justice
          and equality .
        </p>
        <p>
          Fighting to promote social justice in any society cost financial
          resource and Nigeria is no exception. When you contribute any amount
          to the ARLHD ( Advocate of Rule of Law and Human Dignity ) cause , you
          are making social justice in Nigeria gradually become a realit
        </p>
      </div>

      <div className="w-full md:px-4 md:py-4 grid place-items-center">
        <form className="w-full md:px-4 md:p-4  grid place-items-center">
          <div className="md:w-[30%] pb-[20px]">
            <h2> Full Name</h2>
            <input
              type="text"
              placeholder="First Name & Last Name"
              className="w-[100%] h-[30px]  rounded  border-[1px] border-[#000] px-4 py-4"
            />
          </div>
          <div className="md:w-[30%] pb-[20px]">
            <h2> Email</h2>
            <input
              type="email"
              placeholder="Enter Email"
              className="w-[100%] h-[30px]  rounded  border-[1px] border-[#000] px-4 py-4"
            />
          </div>
          <div className="md:w-[30%] pb-[20px]">
            <h2> Amount</h2>
            <input
              type="number"
              placeholder="Enter Amount"
              className="w-[100%] h-[30px]  rounded  border-[1px] border-[#000] px-4 py-4"
            />
          </div>

          <div className="md:w-[30%] pb-[20px]">
            <h2> Phone Number </h2>
            <input
              type="phone"
              placeholder="Enter Email"
              className="w-[100%] h-[30px]  rounded  border-[1px] border-[#000] px-4 py-4"
            />
          </div>
          <div className="md:w-[30%] pb-[20px] w-[60%]">
            <input
              type="submit"
              className=" bg-[#800080] text-white w-[100%] h-[40px]"
              value="DONATE"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Donate;
