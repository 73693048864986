import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Firstslide from "../img/slide1.jpg";
import Secondslide from "../img/advocacy.jpg";
import Thirdslide from "../img/a3.jpg";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

// const images = [
//   Firstslide,
//   "https://source.unsplash.com/random/800x402",
//   "https://source.unsplash.com/random/800x403",
// ];

const Courousel = () => {
  //   const [currentImage, setCurrentImage] = useState(0);

  //   const previousImage = () => {
  //     setCurrentImage((currentImage - 1 + images.length) % images.length);
  //   };

  //   const nextImage = () => {
  //     setCurrentImage((currentImage + 1) % images.length);
  //   };

  return (
    <div className="z-10 mt-[-20px] justify-center grid place-items-center">
      <Carousel
        className="w-full max-w-screen-xl mx-auto "
        showArrows={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
        showThumbs={false}
      >
        <div className="w-full h-[450px] relative ">
          <img
            src={Secondslide}
            alt="community 2"
            className="w-full h-full object-fill "
          />
          <div className="absolute inset-0 grid place-items-center items-center justify-center text-black text-2xl font-semibold bg-opacity-50 bg-black  px-4 py-4 w-[70%] md:w-[60%] self-center">
            <h2 className="md:text-[40px]  text-[20px] text-[#fff] md:leading-10 font-[roboto]">
            We are dedicated to the promotion and protection of human rights and the rule of  law 
            </h2>
          </div>
        </div>
        <div className="w-full h-[450px] relative ">
          <img
            src={Thirdslide}
            alt="no word 2"
            className="w-full h-full object-cover h-[400px]"
          />
          <div className="absolute inset-0 grid place-items-center items-center justify-center text-black text-2xl font-semibold bg-opacity-50 bg-black  px-4 py-4 w-[70%] md:w-[60%] self-center">
            <h2 className="md:text-[40px]  text-[20px] text-[#fff] md:leading-10 font-[roboto]">
            Everyone should get fair and equal treatment, before the law

            </h2>
          </div>
        </div>

        <div className="w-full h-[450px] relative ">
          <img
            src={Firstslide}
            alt="Word 2"
            className="w-full h-full object-cover h-[400px]"
          />
          <div className="absolute inset-0 grid place-items-center items-center justify-center text-black text-2xl font-semibold bg-opacity-50 bg-black  px-4 py-4 w-[70%] md:w-[60%] self-center">
            <h2 className="md:text-[40px]  text-[20px] text-[#fff] md:leading-10 font-[roboto]">
            Human dignity is the bedrock of social justice and a thriving society

            </h2>
         
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Courousel;
