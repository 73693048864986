import { Link } from "react-router-dom";

const Whatwe = () => {
  return (
    <div className="w-[100%] grid place-content-center place-items-center  mt-[50px]">
      <div className="self-center px-4 py-4">
        {" "}
        <h2 className="text-[35px] font-bold font-[roboto] text-[#401f68]"> Our Programmes</h2>
      </div>

      <div className=" py-8 w-[100%] grid md:grid-cols-3 grid-cols-1 px-8 gap-6">
        <Link to="/whowe/legal">
     
        <div className="bg-[#ccc] md:w-[80%] rounded-md shadow-md  md:h-[420px] ">
          <img
            src={require("../img/a5.jpg")}
            className="h-[170px] w-[100%]"
            alt="ngo1"
          />

          <div className="px-4 py-4">
            <h3 className="text-[25px] font-bold font-[roboto] text-[#401f68]">
              Legal Aid Services{" "}
            </h3>
          </div>
          <div className="px-4 py-4">
          <p className="text-[15px] text-justify font-[roboto]font-bold  text-[#fff]">
              {" "}
              We provide pro bono legal aid services to vulnerable individuals
              and marginalised communities, who cannot afford legal
              representation.
            </p>
          </div>
        </div>
        </Link>

        <Link to="/whowe/advocacy">

        <div className="bg-[#ccc] md:w-[80%] rounded-md shadow-md">
          <img
            src={require("../img/a6.jpg")}
            className="h-[170px] w-[100%]"
            alt="ngo2"
          />
          <div className="px-4 py-4">
            <h3 className="text-[25px] font-bold font-[roboto] text-[#401f68]">Advocacy </h3>
          </div>
          <div className="px-4 py-4">
            <p className="text-[15px] text-justify font-[roboto]font-bold  text-[#fff]">
              {" "}
              We are in the business of advocating for good governance, social
              justice, the promotion and protection of human rights and the
              rule of law through engagements and interactions with all tiers of
              government, international organizations and civil society groups.{" "}
            </p>
          </div>
        </div>
        </Link>

        <Link to="/whowe/outreach" >
        <div className="bg-[#ccc] md:w-[80%] rounded-md shadow-md md:h-[420px] ">
          <img
            src={require("../img/ngo3.jpg")}
            className="h-[170px] w-[100%]"
            alt="ngo3"
          />

          <div className="px-4 py-4">
            <h3 className="text-[25px] font-bold font-[roboto] text-[#401f68]">
              Education and Outreach{" "}
            </h3>
          </div>
          <div className="px-4 py-4">
            <p className="text-[15px]  font-bold text-justify  font-[roboto]  text-[#fff]">
              {" "}
              We conduct educational programmes and raise awareness of good
              governance, social justice, human rights, human dignity and rule
              of law through social campaigns, seminars and workshops.
            </p>
          </div>
        </div>
        </Link>
      </div>
    </div>
  );
};

export default Whatwe;
