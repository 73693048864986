import React from "react";
import { useParams } from "react-router-dom";
import Footerbottom from "./components/Footerbottom";
import Navheader from "./components/Navheader";
import { Link } from "react-router-dom";
import "./style.css";

function Whowepage() {
  let { whowe } = useParams();

  return (
    <div className="w-[100%]" id="leadercon">
      <Navheader />
      <div className="w-[100%]   flex px-4 py-4 justify-center content-center items-center">
        <div className="md:w-[100%]   bg-white px-4 py-4 rounded-md ">
          <Link to="/">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </Link>
          <br />
          <br />
          {whowe === "legal" ? (
            <>
              <h2>
                <b className="text-[30px]">Legal Aid Services</b>
              </h2>
              <br />
              <p className=" text-justify text-black">
                At Arolhd, we are particularly focused on providing legal aid
                services for juveniles, with the end goal of upholding the Rule
                of Law and Human Dignity. We seek to protect and advance the
                legal rights of eligible persons by providing legal
                representation, advocacy, and community education that ensures
                equal access to justice. Particularly, we focus on juveniles or
                minors in remand or juvenile homes who may face the possibility
                of being transferred to adult jails upon reaching the age of
                majority while awaiting trial. In addition to addressing legal
                representation, we also integrate training in leadership and
                life-impacting skills to empower young individuals in
                challenging circumstances. By providing Legal Aid for minors, we
                aim to achieve the following:
              </p>
              <br />
              <p>
                1. Protect the Rights of Juveniles: Ensure that incarcerated
                juveniles have access to proper legal representation. Many young
                individuals in remand or juvenile homes may not fully understand
                their rights or have the means to secure legal assistance.
                Providing legal aid ensures that their rights are protected and
                that they have a fair chance in the legal system.
              </p>

              <br />
              <p>
                2. Prevent the Transfer to Adult Jails: One of the primary
                concerns for juveniles in the legal system is the potential
                transfer to adult jails upon reaching the age of majority. We
                work towards preventing such transfers by advocating for
                juvenile-specific facilities and ensuring that the legal system
                recognizes the unique needs and vulnerabilities of young
                individuals.
              </p>
              <br />
              <p>
                3. Navigating Legal Complexities: Our Legal Aid services seek to
                navigate the complexities of the legal system on behalf of
                juveniles. This includes addressing issues related to juvenile
                rights, presenting evidence in their favor, advocating for
                alternative measures that prioritize rehabilitation over
                punitive measures, ensuring that the legal process is fair,
                transparent, and considers the best interests of the young
                individuals involved.
              </p>
              <br />
              <p>
                4. Training in Leadership and Life-Impacting Skills: This
                empowers Juveniles for a Better Future: Beyond legal
                representation, we organize training sessions focused on
                leadership and life-impacting skills. These sessions, through
                education, aim to empower juveniles and adults as the case may
                be with the necessary skills to navigate challenges, make
                informed decisions, and contribute positively to society.
              </p>

              <br />
              <p>
                Most importantly, our aim is to address the Root Causes of
                Juvenile delinquency. We have come to Recognize that legal
                issues often intersect with social and economic challenges.
                Thus, our training component addresses the root causes of
                juvenile delinquency. Our holistic approach to rehabilitation
                aims to break the cycle of involvement in the criminal justice
                system by equipping juveniles with skills that foster personal
                growth and resilience. Hopefully, this will lead to: -
              </p>
              <br />
              <p>
                Resilience building and Confidence, giving juveniles the
                necessary Leadership and life-impacting skills needed to build
                resilience and confidence, enabling them to envision a positive
                future. This not only benefits the individuals directly involved
                but also contributes to the overall well-being of communities by
                fostering responsible and empowered citizens.
              </p>
              <br />
              <p>
                We firmly believe that providing legal representation coupled
                with leadership and life-impacting skills training exemplifies a
                comprehensive approach to advocating for the Rule of Law and
                Human Dignity. By safeguarding the rights of juveniles and
                empowering them with the skills needed for personal growth, we
                strive to create a just and compassionate society, ensuring that
                young individuals receive the support necessary to overcome
                challenges and build promising futures. This serves as a beacon
                of hope to create a path towards rehabilitation and empowerment
                for young individuals entangled in the legal system, ensuring
                that they are given the support they need to overcome challenges
                and build meaningful lives. Our leadership and life-impacting
                skills training will also extend to students in secondary and
                tertiary institutions, and young adults.
              </p>
            </>
          ) : null}

          {whowe === "advocacy" ? (
            <>
              <h2>
                <b className="text-[30px]"> ADVOCACY:</b>
              </h2>
              <br />
              <p className=" text-justify text-black">
                AROLHD seeks to promote diversity. This goal is an essential
                aspect of advocating for the rule of law and human dignity.
                Embracing diversity within the framework of legal and societal
                structures is not only a moral imperative but also a key element
                in ensuring justice, equality, and respect for human rights. Our
                program on diversity focuses on the following:
              </p>
              <br />
              <p>
                1. Inclusive Legal Frameworks: We advocate for creating and
                reinforcing legal frameworks that are inclusive and cater to the
                diverse needs of the population. This includes ensuring that
                laws are not discriminatory and that they protect the rights of
                all individuals, irrespective of their backgrounds.
              </p>
              <br />
              <p>
                2. Representation in Legal Institutions: Diversity in legal
                institutions, including the judiciary, legal professions, and
                law enforcement agencies, is crucial. We advocate and push for
                policies that promote equal opportunities for individuals from
                different ethnic, cultural, gender, and socio-economic
                backgrounds to participate in legal careers.
              </p>{" "}
              <br />
              <p>
                3. Community Engagement and Education: Engagement with diverse
                communities to educate them about their legal rights and
                responsibilities helps in empowering individuals from all walks
                of life, fostering a sense of inclusion and promoting a society
                that respects the dignity of every person.
              </p>
              <br />
              <p>
                4. Addressing Systemic Bias and Discrimination: We engage in
                identifying and addressing systemic biases and discriminatory
                practices within society. This involves challenging
                discriminatory policies, advocating for reforms, and ensuring
                that the rule of law is applied equitably to all members of
                society.
              </p>
              <br />
              <p>
                5. Cultural Competence Training: We seek to work with legal
                professionals, relevant law enforcement officers, and others
                involved in the justice system on cultural competence training.
                This helps in understanding and respecting diverse perspectives,
                thereby promoting fair and just administration of Justice.
              </p>
              <br />
              <p>
                6. Supporting Minority Rights: Our work also involves supporting
                the rights of minority groups. This includes advocating for the
                protection of minority rights, addressing issues of
                discrimination, and actively working towards creating an
                environment where everyone feels secure and respected.
              </p>{" "}
              <br />
              <p>
                7. Collaboration with Diverse Stakeholders: In order to achieve
                our set goals, we are actively collaborating with diverse
                stakeholders, including nonprofits, community groups, and
                international bodies, to collectively address issues related to
                the rule of law and human dignity. Different perspectives and
                experiences contribute to a more comprehensive and effective
                approach.
              </p>
            </>
          ) : null}
          {whowe === "outreach" ? (
            <>
              <h2>
                <b className="text-[30px]"> EDUCATION AND OUTREACH:</b>
              </h2>
              <br />
              <p className=" text-justify text-black">
                At Arolhd, we take Mental Health and Human Dignity Initiatives
                very seriously. Our current programme outline includes:
              </p>
              <br />
              <p>
                - Promoting mental health awareness, support, and resources
                within the framework of upholding human rights and dignity
                through Educational Workshops on mental health, focused on the
                intersection with human rights.
              </p>
              <br />
              <p>
                - We seek to educate our targeted demography on the effect of
                stigma, discrimination, and human rights violations related to
                mental health. Through the Provision of information on coping
                mechanisms, resilience, and self-care.
              </p>
              <br />
              <p>
                We are currently Establishing a legal support system for
                individuals facing discrimination due to mental health
                conditions. Through this, our goal is to advocate for policies
                which protect the rights of individuals with mental health
                challenges.
              </p>
              <br />
              <p>
                Also important is the need to Collaborate with legal
                professionals to provide pro bono services for mental
                health-related cases through Community Support Groups, which
                seek to create a safe space for individuals to share
                experiences. We also see the need to Include a human rights
                perspective in discussions on mental health challenges as a
                must. We think it is also important to Provide resources for
                community members to access mental health services.
              </p>
              <br />
              <p>
                At present, we are organizing Training sessions for healthcare
                Professionals, on the importance of human rights in mental
                health care. While Emphasizing the role of proper ethical
                considerations and respect for human dignity in mental health
                practices.
              </p>{" "}
              <br />
              <p>
                We are also in the business of Developing public awareness
                campaigns on mental health and its connection to human dignity.
                We seek to make the aforementioned more effective by Utilizing
                multiple channels of media communication to disseminate
                information and reduce stigma.
              </p>
              <br />
              <p>
                Collaborating with influencers and advocates to amplify this
                messaging is of great importance. It is our goal to Establish a
                helpline or crisis intervention programme for individuals in
                immediate need, and also train volunteers to provide empathetic
                support and connect individuals to appropriate resources.
              </p>{" "}
              <br />
              <p>
                At the moment, we are interested in conducting research on the
                intersection of mental health and human rights. While also
                advocating for policies informed by evidence-based research to
                protect the mental health of individuals. Collaborating and
                entering into partnership with existing mental health
                organizations to leverage resources and expertise is of great
                importance too.
              </p>
            </>
          ) : null}
        </div>
      </div>

      <Footerbottom />
    </div>
  );
}

export default Whowepage;
