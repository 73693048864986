import React from "react";
import Logo from "../img/log.png";

function Footer() {
  return (
    <div
      className="grid  md
    md:grid-cols-2   grid-cols-1 gap-10 w-[100%] bg-[#401f68] px-10 py-10 justify-between border-b-2 border-blue-50"
    >
      <div className=" grid place-content-end">
        <h2 className="text-[#ffffff] text-[30px] font-bold ">
          {" "}
          <img src={Logo} alt="" className="w-[200px] h-[80px]" />
        </h2>
        <p className="text-[16px] text-[#fff] font-[roboto]">
          Advocates of rule of Law and Human Dignity (Seeking the attainment of
          a more equitable society)
        </p>
      </div>
      <div>
        <h2 className="text-[#ffffff] text-[20px] font-bold"> CONTACT US</h2>

        <p className="text-[16px] text-[#fff]">
          {" "}
          <br />
          1300 N. Sam Huston PKwy E Suite 160,
          <br />
          Houston, TX 77032
        </p>
        <br />
        <p className="text-[16px] text-[#fff] mt-5">
          Bamako Street, Wuse Zone 1
          <br />
          Abuja, FCT Nigeria
        </p>
        {/* 
        <p className="text-[16px] text-[#fff]">EMAIL: sngo@gmail.com</p>
        <p className="text-[16px] text-[#fff]">PHONE: 0708900000</p> */}
      </div>
    </div>
  );
}

export default Footer;
