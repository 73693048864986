import React from "react";

const Calltoaction = () => {
  return (
    <div className="px-10 py-10 w-[100%] bg-[#ccc] mt-[30px] justify-center place-items-center grid">
      <h2 className="text-[20px] font-bold text-center text-[#401f68] ">
        At AROLHD, we value human dignity irrespective of race , gender ,
        religion , ethnicity or social standing
      </h2>

      <p className="text-center  md:mt-[12px] mt-[12px] font-[roboto] text-[#fff]">
        All human beings are born free and equal in dignity and rights -
        Eleanor Roosevelt.{" "}
      </p>
    </div>
  );
};

export default Calltoaction;
