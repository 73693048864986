import React from "react";
import Footerbottom from "./components/Footerbottom";
import Navheader from "./components/Navheader";
import { Link } from "react-router-dom";
import "./style.css";

function Leadership() {
  return (
    <div className="w-[100%] " id="leadercon">
      <Navheader />

      <div className="w-[100%] md:flex  md:h-full  gap-[20px] justify-center content-center items-center px-4 py-4 ">
        <div className="grid md:grid-cols-3   grid-cols-1 w-[100%] gap-[20px] mt-[100px]">
          <div className="md:w-[300px] w-[100%]  grid grid-cols-1 rounded bg-white">
            <img
              src={require("../src/img/oyewusi.jpeg")}
              alt=""
              className="h-[300px] w-[100%]"
            />
            <div className=" bg-black px-6 py-6">
              <h3 className=" text-white">
                Professor Oyewusi Gureje:
                <br /> Chairman
             
            
              </h3>
              <br /> 
              <Link to='/leaders/oyewusi' className="px-4 py-4 bg-white rounded">View Profile</Link>
           
            </div>
          </div>

          <div className="md:w-[300px] w-[100%]  grid grid-cols-1 rounded bg-white">
            <img
              src={require("../src/img/opeoluwa.jpeg")}
              alt=""
              className="h-[300px] w-[100%]"
            />
            <div className=" bg-black px-6 py-6">
              <h3 className=" text-white">
              Dr.  Opeoluwa Badaru::
         
             
            
              </h3>
              <br /> 
              <Link to="/leaders/opeoluwa"  className="px-4 py-4 bg-white rounded">View Profile</Link>
           
            </div>
          </div>
          <div className="md:w-[300px] w-[100%]  grid grid-cols-1 rounded bg-white">
            <img
              src={require("../src/img/olodotun.jpeg")}
              alt=""
              className="h-[300px] w-[100%]"
            />
            <div className=" bg-black px-6 py-6">
              <h3 className=" text-white">
              Dr Oludotun Adetunberu
      
             
            
              </h3>
              <br /> 
              <Link to="/leaders/oludotun" className="px-4 py-4 bg-white rounded">View Profile</Link>
           
            </div>
          </div>
          <div className="md:w-[300px] w-[100%]  grid grid-cols-1 rounded bg-white">
            <img
              src={require("../src/img/bolajoko.jpeg")}
              alt=""
              className="h-[300px] w-[100%]"
            />
            <div className=" bg-black px-6 py-6">
              <h3 className=" text-white">
              Dr Bolajoko Bayo-Ajayi:
               
             
            
              </h3>
              <br /> 
              <Link to="/leaders/bolajoko" className="px-4 py-4 bg-white rounded">View Profile</Link>
           
            </div>
          </div>
          <div className="md:w-[300px] w-[100%]  grid grid-cols-1 rounded bg-white">
            <img
              src={require("../src/img/olawale.jpeg")}
              alt=""
              className="h-[300px] w-[100%]"
            />
            <div className=" bg-black px-6 py-6">
              <h3 className=" text-white">
                   Olawale Balogun, Esq.:
                <br /> Director
             
            
              </h3>
              <br /> 
              <Link to="/leaders/olawale" className="px-4 py-4 bg-white rounded">View Profile</Link>
           
            </div>
          </div>
          <div className="md:w-[300px] w-[100%]  grid grid-cols-1 rounded bg-white">
            <img
              src={require("../src/img/joan.jpeg")}
              alt=""
              className="h-[300px] w-[100%]"
            />
            <div className=" bg-black px-6 py-6">
              <h3 className=" text-white">
              Joan Nwuli, Esq.
                <br /> Director
             
            
              </h3>
              <br /> 
              <Link to="/leaders/joan" className="px-4 py-4 bg-white rounded">View Profile</Link>
           
            </div>
          </div>
          <div className="md:w-[300px] w-[100%]  grid grid-cols-1 rounded bg-white">
            <img
              src={require("../src/img/adebanji.jpeg")}
              alt=""
              className="h-[300px] w-[100%]"
            />
            <div className=" bg-black px-6 py-6">
              <h3 className=" text-white">
              Adebanji Dada, Esq
                <br /> Lead Director
             
            
              </h3>
              <br /> 
              <Link to="/leaders/adebanji" className="px-4 py-4 bg-white rounded">View Profile</Link>
           
            </div>
          </div>
        </div>
      </div>

      <Footerbottom />
    </div>
  );
}

export default Leadership;
