import React from "react";
import { Link } from "react-router-dom";
import Logo from '../img/log.png';

const Navheader = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <nav className="bg-[#401f68] p-4 relative z-10">
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/" className="text-white text-lg font-semibold">
           <img  src={Logo} alt="" className="w-[200px] h-[80px]" />
          </Link>
          <div className="lg:hidden">
            <button
              onClick={toggleNavbar}
              className="text-white focus:outline-none focus:text-white"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
          <div className={`lg:flex lg:items-center hidden`}>
            <Link to="/" className="text-white p-2 hover:bg-gray-700">
              Home
            </Link>
            <Link to="/wowearenow" className="text-white p-2 hover:bg-gray-700">
              Who we are
            </Link>
            <Link to="/whatwedo" className="text-white p-2 hover:bg-gray-700">
              What we do
            </Link>
            <Link to="/leadership" className="text-white p-2 hover:bg-gray-700">
              Leadership team
            </Link>
            <Link to="/contactus" className="text-white p-2 hover:bg-gray-700">
              Contact
            </Link>

            <Link
              to="/donate"
              className="text-black shadow-md  bg-slate-200 px-4 py-4 border-spacing-2 rounded-md border-black hover:bg-gray-700"
            >
              Donate
            </Link>
          </div>
        </div>
      </nav>
      <div
        className={` md:hidden ${
          isOpen ? "flex flex-col absolute bg-[#401f68] w-full z-50" : "hidden"
        }`}
      >
        <Link to="/" className="text-white p-2 hover:bg-gray-700">
          Home
        </Link>
        <Link to="/wowearenow" className="text-white p-2 hover:bg-gray-700">
          Who we are
        </Link>
        <Link to="/whatwedo" className="text-white p-2 hover:bg-gray-700">
          What we do
        </Link>
        <Link to="/leadership" className="text-white p-2 hover:bg-gray-700">
          Leadership Team
        </Link>
        <Link to="/contactus" className="text-white p-2 hover:bg-gray-700">
          Contact
        </Link>

        <Link
          to="/donate"
          className="text-black shadow-md  bg-slate-200 px-4 py-4 border-spacing-2 rounded-md border-black hover:bg-gray-700"
        >
          Donate
        </Link>
      </div>
    </>
  );
};

export default Navheader;
