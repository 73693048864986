import React from "react";

function Footerbottom() {
  return (
    <div className=" bg-black px-4 py-4">
      <p className="text-[#fff] text-[12px]"> @COPYRIGHT AROLHD 2024 </p>
    </div>
  );
}

export default Footerbottom;
