import React from "react";
import { useParams } from "react-router-dom";
import Footerbottom from "./components/Footerbottom";
import Navheader from "./components/Navheader";
import { Link } from "react-router-dom";
import "./style.css";

function Leadersdetails() {
  let { leadership } = useParams();

  return (
    <div className="w-[100%]" id="leadercon">
      <Navheader />
      <div className="w-[100%] md:h-screen   flex px-4 py-4 justify-center content-center items-center">
        <div className="md:w-[60%]   bg-white px-4 py-4 rounded-md">
          <Link to="/leadership">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </Link>
          <br />
          <br />
          {leadership === "oyewusi" ? (
            <>
              <h2>
                <b className="text-[30px]">Professor Oyewusi Gureje:</b>
              </h2>
              <br />
              <p className=" text-justify text-black">
                A Professor of Psychiatry and Director of the WHO Collaborating
                Centre for Research and Training in Mental Health, Neuroscience,
                and Substance Abuse, Department of Psychiatry, University of
                Ibadan. Professor Gureje is internationally recognized as a
                leading global mental health researcher with important
                contributions to psychiatric nosology, mental-physical
                comorbidity, cross-cultural epidemiology of mental disorders,
                aging in sub-Saharan Africa, and the development of integrated
                service for people with mental health conditions. As the Founder
                and Director of the Mental Health Leadership and Advocacy
                Programme (mhLAP) at the University of Ibadan, he led in
                leadership capacity building and mental health service and
                policy developments in the five anglophone countries of Gambia,
                Ghana, Liberia, Nigeria, and Sierra Leone. Further, as the
                President of Association of African Psychiatrists and Allied
                Professions (AAPAP), he convened, in 2009, and in collaboration
                with the WHO and the World Psychiatric Association, a policy
                roundtable on scaling up mental health in sub-Saharan Africa,
                attended by the health ministers or their representatives of 8
                sub-Sahara African countries.
              </p>
            </>
          ) : null}

          {leadership === "opeoluwa" ? (
            <>
              <h2>
                <b className="text-[30px]">Dr. Ope Badaru:</b>
              </h2>
              <br />
              <p className=" text-justify text-black">
                Dr. Ope Badaru has over 20 years of law practice in multiple
                jurisdictions. She has a wealth of professional experience
                working in the areas of human rights, immigration, business, and
                real estate law. Shas successfully represented immigration
                clients on a range of matters and previously worked as a public
                policy and advocacy consultant on immigrant rights and public
                policy.
              </p>
            </>
          ) : null}
          {leadership === "oludotun" ? (
            <>
              <h2>
                <b className="text-[30px]">Dr Oludotun Adetunberu:</b>
              </h2>
              <br />
              <p className=" text-justify text-black">
                A Peace and Security scholar with keen interest in good
                governance, human rights and social justice. Dr Adetunberu is
                the President-in-Council, Institute of Global Peace and Conflict
                Management. An alumnus and Ex- Global President of Ekiti State
                University Alumni Association, Dr Adetunberu is also a member of
                Ekiti State University Governing Council. He lectures at
                American University, Curacao and National Open University,
                Nigeria. To his credit, Dr. Adetunberu has written many research
                articles and presented numerous papers in the areas of Peace and
                Security. He is also the pioneer Director General, Homeland
                Intelligence Agency, Ekiti State.
              </p>
            </>
          ) : null}

          {leadership === "bolajoko" ? (
            <>
              <h2>
                <b className="text-[30px]">Dr Bolajoko Bayo-Ajayi:</b>
              </h2>
              <br />
              <p className=" text-justify text-black">
                Principal Consultant at Purple Pearl Consulting Ltd – a
                collaborative community of creative talents who partner with
                clients to execute innovative ideas that sustainably grow market
                share . The company offers services in brand management, concept
                ideation, business planning and human capital development
                (training & coaching). A graduate of Economics from the Obafemi
                Awolowo University, Ile-Ife and an MBA (Marketing) from
                University of Lagos. She holds a professional diploma in
                Marketing from the Chartered Institute of Marketing (CIM) UK. A
                marketing and brand communication expert, with about two decades
                of experience in two big multinationals – Guinness (Diageo) and
                Coca-Cola. She has managed several brands, including several
                billion dollar brands and nurtured them to achieve sustainable
                growth.
              </p>
            </>
          ) : null}

          {leadership === "olawale" ? (
            <>
              <h2>
                <b className="text-[30px]">Olawale Balogun, Esq.::</b>
              </h2>
              <br />
              <p className=" text-justify text-black">
                Barrister Wale Balogun is a legal practitioner and a Partner at
                Greenbridge Partners, a law firm based in Abuja, Nigeria. He is
                a graduate of the prestigious University of Ibadan and was
                called to the Nigerian Bar in 2003. He graduated with a
                Distinction in MBA from the prestigious Coventry Business
                School, Coventry University, United Kingdom. This is in addition
                to his postgraduate degree of Master of Law (LL.M) at the
                University of Jos, Plateau State. He was the Managing Partner at
                DPC Attorneys and Solicitors before joining Greenbridge
                Partners. Mr. Balogun is a notary public of the Federal Republic
                of Nigeria. A Co-Founder and Director of Skills and Advocacy of
                Centre for Applied Policy and Social Advocacy (CAPSA), a
                not-for-profit organization providing pro-bono consultancy
                services to indigent Nigerians and developing a series of
                policies and legal frameworks that have culminated into draft
                bills in the Nigerian legislature.
              </p>
            </>
          ) : null}

          {leadership === "joan" ? (
               <>
               <h2>
                 <b className="text-[30px]">Joan Nwuli, Esq:</b>
               </h2>
               <br />
            <p className=" text-justify text-black">
              Attorney Joan Nwuli is a consummate legal practitioner with over
              thirty years of experience. She has tried numerous criminal cases
              to the jury and has done several criminal appeals. She has also
              represented numerous clients on immigration matters. Ms. Nwuli is
              a member of the following Bar Associations: the American Trial
              Lawyers Association, American Bar Association, Harris County
              Criminal Lawyers Association, Houston Young Lawyers Foundation and
              the Texas State Bar College. She also belongs to a number of civic
              organizations including the African Women Alliance, Houston league
              of business and professional women and the Texas Women Empowerment
              Foundation.
            </p>
            </>
          ) : null}

          {leadership === "adebanji" ? (
               <>
               <h2>
                 <b className="text-[30px]">Adebanji Dada, Esq:</b>
               </h2>
               <br />
            <p className=" text-justify text-black">
              Adebanji Dada Esq. - Lead Director Adebanji Dada is a Barrister
              and Solicitor of the Supreme Court of Nigeria with over twenty
              years of experience. He has passion for human rights, good
              governance and social justice. Banji obtained his Bachelor of Law
              (LL.B), Master of Law (LL.M) and Master of Business Administration
              (MBA) degrees from the University of Ibadan, Northwestern Pritzker
              School of Law, Chicago and Nottingham University Business School,
              England, respectively. Barrister Dada is a member of the
              Commonwealth Association of Legislative Counsel and served as a
              Senior Legislative Aide at the National Assembly, Nigeria. He has
              previously worked as a Company Secretary and Executive Director
              for both profit and nonprofit organizations. He also once served
              as a member, Economic Development Commission and Cultural
              Awareness Commission at Hoffman Estates, Illinois, U.S.A. Banji is
              one of the Co-Founders of Greenbridge Partners (Legal
              Practitioners) - an Abuja based law firm. A member of IBB
              International Golf and Country Club, Banji enjoys playing golf.
            </p>
            </>
          ) : null}
        </div>
      </div>

      <Footerbottom />
    </div>
  );
}

export default Leadersdetails;
