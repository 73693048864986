import React from "react";
import { Link } from "react-router-dom";

function Whoweare() {
  return (
    <div className="px-[50px] py-[50px] grid place-items-center place-content-center md:grid-cols-3 grid-cols-1  gap-4">
   
   <Link to="wowearenow">

   <div className="place-items-center grid border-r-[5px] border-l-[5px] px-4  border-[#401f68] h-[300px]">
        <h2 className="font-bold text-[25px] text-[#401f68]"> WHO WE ARE</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-20 h-20 mt-[20px]  text-[#401f68]"
        >
          <path
            fillRule="evenodd"
            d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
            clipRule="evenodd"
          />
          <path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
        </svg>

        <p className="text-[15px]  text-center mt-[20px]  font-bold font-[roboto]   text-[#401f68]">
          {" "}
          Non-profit organization dedicated to the promotion, protection of human rights , equity and justice globally.{" "}
          <Link to="wowearenow" className="text-[#401f68]">Learn More</Link>
        </p>
     
      </div>

   </Link>
   
   <Link to="wowearenow" >
      <div className="place-items-center grid  border-r-[5px] border-l-[5px] px-4 border-[#401f68] h-[300px]">
        <h2 className="font-bold text-[25px] text-[#401f68]"> WHAT WE DO</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-20 h-20 mt-[20px] text-[#401f68]"
        >
          <path
            fillRule="evenodd"
            d="M12 2.25a.75.75 0 01.75.75v.756a49.106 49.106 0 019.152 1 .75.75 0 01-.152 1.485h-1.918l2.474 10.124a.75.75 0 01-.375.84A6.723 6.723 0 0118.75 18a6.723 6.723 0 01-3.181-.795.75.75 0 01-.375-.84l2.474-10.124H12.75v13.28c1.293.076 2.534.343 3.697.776a.75.75 0 01-.262 1.453h-8.37a.75.75 0 01-.262-1.453c1.162-.433 2.404-.7 3.697-.775V6.24H6.332l2.474 10.124a.75.75 0 01-.375.84A6.723 6.723 0 015.25 18a6.723 6.723 0 01-3.181-.795.75.75 0 01-.375-.84L4.168 6.241H2.25a.75.75 0 01-.152-1.485 49.105 49.105 0 019.152-1V3a.75.75 0 01.75-.75zm4.878 13.543l1.872-7.662 1.872 7.662h-3.744zm-9.756 0L5.25 8.131l-1.872 7.662h3.744z"
            clipRule="evenodd"
          />
        </svg>

        <p className="text-[15px]  text-center mt-[20px]  font-bold font-[roboto]  text-[#401f68]">
        We provide Legal aid services, Advocacy, and organize impactful education and Outreach programmes for vulnerable communities. 
        <Link to="whatwedo" className="text-[#401f68]">Learn More</Link>
        </p>

      </div>
      </Link>
      <Link to="/contactus" >

      <div className="place-items-center grid  border-r-[5px] border-l-[5px] px-4 md:border-r-[5px] border-[#401f68] h-[300px]">
        <h2 className="font-bold text-[25px] text-[#401f68]"> GET INVOLVED</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-20 h-20 mt-[20px]  text-[#401f68]"
        >
          <path
            fillRule="evenodd"
            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clipRule="evenodd"
          />
        </svg>

        <p className="text-[15px]  text-center mt-[20px]  font-bold  font-[roboto] text-[#401f68]">
          {" "}
          Take action by joining our campaigns and supporting us through funding.    <Link to="/contactus" className="text-[#800080]">Contact us</Link>
        </p>
     
      </div>
      </Link>
    </div>
  );
}

export default Whoweare;
