import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Donate from "./Donate";
import Whowearenow from "./Whowearenow";
import Whatwedo from "./Whatwedo";
import Leadership from "./Leadership";
import Contactus from "./Contactus";
import Leadersdetails from "./Leadersdetails";
import Whowepage from "./Whowepage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/wowearenow" element={<Whowearenow/>} />
        <Route path="/whatwedo" element={<Whatwedo/>} />
        <Route path="/leadership" element={<Leadership/>} />
        <Route path="/contactus" element={<Contactus/>} />
        <Route path="/leaders/:leadership" element={<Leadersdetails/>} />
        <Route path="/whowe/:whowe" element={<Whowepage/>} />
      </Routes>
    </Router>
  );
}

export default App;
