import React from "react";
import Footerbottom from "./components/Footerbottom";
import Navheader from "./components/Navheader";

function Whatwedo() {
  return (
    <div className="w-[100%] h-screen">
      <Navheader />

      <div className="w-[100%] md:flex flex-1  gap-4 h-screen  justify-center content-center items-center px-4 py-4 ">
      
        <div className=" md:w-[70%]  rounded-md shadow-md px-4 py-4">
          <h2 className=" text-[35px] text-[#401f68] font-[900]">
            What we do{" "}
          </h2>
          <p className="text-justify mb-10">
            <b>Legal Aid Services: </b> ARLHD provides free legal aid services
            to vulnerable individuals and marginalized communities who cannot
            afford legal representation. Our team of legal experts works to
            ensure that justice is accessible to everyone.
          </p>
          <p className="text-justify mb-10">
            <b>Advocacy:</b> We advocate for promotion and protection of human
            rights and rule of law through engagement and interactions with
            governments, international organizations, and civil society groups,
            to draw attention and proffer solutions to relevant pressing issues.
          </p>
          <p className="text-justify">
            <b>Education and Outreach:</b> ARLHD conducts educational programs
            and raises awareness of human rights, rule of law, human dignity,
            social justice and good governance through campaigns, workshops, and
            seminars for disadvantaged communities, youths, students, civil
            society organizations, and the general public.
          </p>
        </div>
      </div>

      <Footerbottom />
    </div>
  );
}

export default Whatwedo;
