import Calltoaction from "./components/Calltoaction";
import Courousel from "./components/Courousel";
import Footer from "./components/Footer";
import Footerbottom from "./components/Footerbottom";
import Navheader from "./components/Navheader";
import Whatwe from "./components/Whatwe";
import Whoweare from "./components/Whoweare";

const Home = () => {
  return (
    <div>
      <Navheader />
      <Courousel />
      <Whatwe />
      <Calltoaction />
      <Whoweare/>
      <Footer/>
      <Footerbottom/>
    </div>
  );
};

export default Home;
